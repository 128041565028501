<template>
    <modal class="collectDepositModel" :isShow="isShow">
        <div class="header">收取定金
            <i class="iconfont icon-guanbi" @click="hide()"></i>
        </div>
        <div class="modal-body">
            <div class="info-box">
                <div class="tr-box">
                    <div class="lable-text">收据编号：</div>
                    <div class="from-input"><input type="text"/></div>
                </div>
                <div class="tr-box">
                    <div class="lable-text">付款方式：</div>
                    <div class="from-input">
                        <el-select v-model="Payment_ID" placeholder="请选择" value-key="item">
                            <el-option  v-for="item in payTypeList" :key="item.PAY_ID"  :label="item.PAY_NAME" :value="item.PAY_ID"> </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="tr-box">
                    <div class="lable-text">付款金额：</div>
                    <div class="from-input"><input-pattern pattern="money" type="text"/></div>
                </div>
                <div class="tr-box">
                    <div class="lable-text">备注：</div>
                    <div class="from-input"><input type="text"/></div>
                </div>
            </div>
            
            <div class="list-box">
                <div class="title">已付列表</div>
                <div class="table-box" ref="kitNameTable">
                    <el-table class="el-table--scrollable-y" style="width: 100%;" border  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        :data="paymentTableData" highlight-current-row>
                        <el-table-column label="选择" width="40" type="selection"/>
                        <el-table-column prop="User_Code" label="收据编号" min-width="65"></el-table-column>
                        <el-table-column prop="User_Name" label="付款方式" min-width="58"></el-table-column>
                        <el-table-column prop="User_Name" label="金额（元）" min-width="78"></el-table-column>
                        <el-table-column prop="User_Name" label="收款人" min-width="44"></el-table-column>
                        <el-table-column prop="User_Name" label="收款时间" min-width="132"></el-table-column>
                        <el-table-column prop="User_Name" label="备注" min-width="70"></el-table-column>
                        <el-table-column label="操作" width="60">
                            <template #default="scope">
                                <el-button class="revoke-bnt" type="text" size="small" @click="revoke(scope.row)">
                                    <i class="iconfont icon-chexiao1"></i>撤销
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected">确认</button>
        </div>
    </modal>
</template>

<script>
// 收取定金
export default {
    name:'collectDepositModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**付款方式数据 */
            payTypeList:[],
            /**选中的 付款方式 */
            Payment_ID:'',
            //已付款数据
            paymentTableData:[{},{}]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            if(this.payTypeList.length==0){
                this.$cacheData.AllPayTypes().then((d)=>{
                    if(d){
                        let all=[]
                        d.forEach(item => {
                            item.PayTypes.forEach((type)=>{
                                all.push(type)
                            })
                        });
                        this.payTypeList=all;
                    }
                }).catch((e)=>{
                    this.$message.error('加载付款方式数据失败：'+e,);
                    console.log('加载付款方式数据失败：'+e);
                })
            }
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
        },
        /**撤销 */
        revoke(){
            this.$confirm("你确定要撤销这笔定金吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
                type: 'warning',
				callback: (name) => {
					if(name=='confirm'){
						console.log("确定")
					}
				},
			});
        }
    }
}
</script>

<style lang="scss">
@import './collectDepositModel.scss'
</style>